"use client";

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Divider,
  useTheme,
  useMediaQuery,
  TextField,
  IconButton,
  Container,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import Image from "next/legacy/image";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/Share";
import { ProductConfigsProps, ProductProps } from "@/type/product";
import toast from "react-hot-toast";

import { getQuantity, getQuantityOnBlur } from "@/utils/quantityUtils";
import { useCartDrawer } from "@/context/cart";
import { Company } from "@/type/company";
import { useTranslation } from "@/app/i18n/client";
import { useRouter } from "next/navigation";
import { NextArrow, PrevArrow } from "@/components/slider";
import InlineLightBox from "@/components/light-box";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

type Props = { product: ProductProps; lang: string; company: Company };

export default function ProductDetails({ product, company, lang }: Props) {
  const { t } = useTranslation(lang, "product", {});

  const { addProductToCart, toggleDrawer } = useCartDrawer();

  const router = useRouter();

  const [productConfigData, setProductConfigData] =
    useState<ProductConfigsProps>();

  const {
    name,
    subDescription,
    description,
    sku,
    productConfigs,
    images,
  } = product;

  const [productData, setProductData] = useState<
    ProductConfigsProps | ProductProps
  >();

  useEffect(() => {
    if (productConfigData) {
      setProductData(productConfigData);
    } else {
      setProductData(product);
    }
  }, [product, productConfigData]);

  const { regularPrice, isOnSale, onSalePrice } = productData || {};

  const [productImages, setProductImages] = useState<string[]>([]);


  useEffect(() => {
    if (productConfigData?.images && productConfigData.images.length > 0) {
      setProductImages(productConfigData.images);
    } else {
      setProductImages(images);
    }
  }, [ images, productConfigData]);

  const [itemQuantity, setItemQuantity] = useState<number | null>(1);

  const totalItemsPrice =
    itemQuantity &&
    itemQuantity * Number(isOnSale ? onSalePrice : regularPrice || 1) + 60; //Get Shipping cost from API

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleInputQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    const numericValue = Number(value);

    if (!isNaN(numericValue) && numericValue >= 1) {
      setItemQuantity(numericValue);
    } else if (value === "") {
      setItemQuantity(null);
    }
  };

  const handleSelectedProductConfig = (productConfig: ProductConfigsProps) => {
    const productConfigData = {
      ...product,
      ...productConfig,
      productId: productConfig.id,
    };
    setProductConfigData(productConfigData);
  };

  const handleBuyNow = () => {
    router.push("/cart");
    toggleDrawer(false);
  };

  const imageSlides = productImages?.map((img) => ({
    src: img,
  }));

  return (
    <Container>
    <Box sx={{ px: { xs: "0px", lg: "0px" }, py: { xs: "16px", lg: "40px" } }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box sx={{ height: { xs: "320px", lg: "500px" }, position: "relative" }}>
            <InlineLightBox slides={imageSlides} plugins={[Thumbnails]} />
          </Box>
        </Grid>
       

        <Grid item xs={12} md={6} sx={{mt: {xs: '32px'}}}>
          <Box
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(2, auto)",
              columnGap: { xs: "16px", md: "unset" },
              gridTemplateAreas: {
                md: `"desc favorite"
                    " rate rate"
                    " price price"
                    " delivery delivery"
                    " info info"
                    " delivery-data delivery-data"
                    " product-data product-data" `,
                xs: `
                    "desc favorite"
                    "desc rate"
                    "desc price"
                    "delivery delivery"
                    "info info"
                    "delivery-data delivery-data" 
                    "product-data product-data"`,
              },
            }}
            >
              <Box
                className={`block-text ${isMobile ? "text-3" : " text-2"}`}
                sx={{ gridArea: "desc", width: { xs: "95%", lg: "364px" }, mb: "16px" }}
              >
                <Typography
                  className="block-text text-2"
                  sx={{
                    fontSize: { xs: "18px", lg: "28px" },
                    fontWeight: "600",
                    pb: "8px",
                    color: "black",
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  variant="subtitle1"
                >
                  {subDescription}
                </Typography>
              </Box>

            <Box
              sx={{
                gridArea: "favorite",
                display: "flex",
                gap: "20px",
                color: "#959595",
                justifySelf: { xs: "flex-end", lg: "end" },
                mb: "12px",
              }}
            >
              {/* TODO: enable this when favorite functionality is implemented */}
              {/* <FavoriteBorderIcon /> */}
              <ShareIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  toast.success(t("copied-to-clipboard"));
                }}
              />
            </Box>

            {/* TODO: enable this when rating functionality is implemented */}
            {/* <Box
              sx={{
                gridArea: "rate",
                height: "19px",
                justifySelf: { xs: "flex-end", md: "start" },
                alignSelf: "start",
                mb: "24px",
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <StarRateRoundedIcon
                  sx={{
                    color: "rgba(234, 168, 0, 1)",
                    width: "16px",
                    height: "16px",
                  }}
                />
                <StarRateRoundedIcon
                  sx={{
                    color: "rgba(234, 168, 0, 1)",
                    width: "16px",
                    height: "16px",
                  }}
                />
                <StarRateRoundedIcon
                  sx={{
                    color: "rgba(234, 168, 0, 1)",
                    width: "16px",
                    height: "16px",
                  }}
                />
                <StarOutlineRoundedIcon
                  sx={{
                    color: "rgba(0, 0, 0, 0.4)",
                    width: "16px",
                    height: "16px",
                  }}
                />
                <StarOutlineRoundedIcon
                  sx={{
                    color: "rgba(0, 0, 0, 0.4)",
                    width: "16px",
                    height: "16px",
                  }}
                />

                <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  {"(5)"}
                </Typography>
              </Box>
            </Box> */}

            <Box
              sx={{
                gridArea: "price",
                justifySelf: { xs: "flex-end", md: "start" },
                alignSelf: "start",
                mb: "16px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "20px", lg: "24px" },
                  fontWeight: "700",
                  color: "secondary.main",
                }}
              >
                {`${isOnSale ? onSalePrice : regularPrice} ${t(
                  company?.currency || "USD"
                )}`}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "14px", lg: "18px" },
                  color: "secondary.main",
                  textDecoration: "line-through",
                  opacity: 0.4,
                  display: isOnSale ? "block" : "none",
                }}
              >
                {`${regularPrice} ${t(company?.currency || "USD")}`}
              </Typography>
            </Box>
            {product?.metadata?.showDeliveryNote && (
              <Box
                sx={{
                  gridArea: "delivery",
                  display: "flex",
                  gap: "16px",
                  alignItems: "center",
                  height: "16px",
                  alignSelf: "start",
                  mb: { xs: "16px", lg: "15px" },
                }}
              >
                <Image src={"/images/cart.svg"} alt="" width={24} height={16} />
                <Typography sx={{ fontSize: "14px" }}>
                  {
                    product?.metadata[
                      `${lang}DeliveryNote` as
                        | "enDeliveryNote"
                        | "arDeliveryNote"
                    ] as any
                  }
                </Typography>
              </Box>
            )}
            {product?.metadata?.showDescriptionBox && (
              <Box
                sx={{
                  gridArea: "info",
                  height: "100%",
                  backgroundColor: "secondaryBackground",
                  mb: { lg: "20px" },
                  px: { xs: "32px", lg: "24px" },
                  py: "24px",
                  width: "100%",
                  // p: "50px",
                }}
              >
                <Grid container spacing={2} rowSpacing={2} direction={"row"}>
                  {product?.metadata?.props.map((prop) => {
                    if (prop.showInDescriptionSection) {
                      return (
                        <Grid item xs={3} key={prop.id}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: "300",
                                mb: "8px",
                              }}
                            >
                              {t(prop[`${lang}Label` as "enLabel" | "arLabel"])}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "700" }}
                            >
                              {t(prop[`${lang}Value` as "enValue" | "arValue"])}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Box>
            )}

            <Box
              sx={{
                gridArea: "product-data",
                px: { xs: "16px", lg: "32px" },
                py: { xs: "16px", lg: "24px" },
                boxShadow: {
                  xs: " 0px 0px 10px -2px #8200000A",
                  lg: "2px 2px 15px 0px #AAAAAA26",
                },
                mt: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", lg: "column" },
                  gap: { lg: "8px" },
                  alignItems: { xs: "center", lg: "flex-start" },
                  justifyContent: "space-between",
                  mb: "20px",
                }}
              >
                <Typography sx={{ fontSize: "16px" }}>
                  {t("quantity")}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    height: { xs: "40px", lg: "48px" },
                    width: { xs: "60%", lg: "100%" },
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    sx={{
                      px: "0px",
                      minWidth: { xs: "48px", lg: "25%" },
                      textAlign: "center",
                      backgroundColor: "primary.main",
                      borderRadius: "0px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "4px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                    }}
                    onClick={() =>
                      setItemQuantity(getQuantity("increment", itemQuantity))
                    }
                  >
                    <AddOutlinedIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: "white",
                      }}
                    />
                  </Button>
                  <TextField
                    value={itemQuantity}
                    type="number"
                    onChange={handleInputQuantityChange}
                    onBlur={() =>
                      setItemQuantity(getQuantityOnBlur(itemQuantity))
                    }
                    InputProps={{
                      sx: {
                        "& input": {
                          textAlign: "center",
                        },
                      },
                    }}
                    sx={{
                      width: { width: "100px", lg: "50%" },
                      backgroundColor: "secondaryBackground",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      p: "0px",
                      "& .MuiInputBase-root": {
                        height: "100%",
                        px: "0px",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                  />
                  <Button
                    sx={{
                      minWidth: { xs: "48px", lg: "25%" },
                      textAlign: "center",
                      backgroundColor: "#EEEEEE",
                      borderRadius: "0px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#EEEEEE",
                      },
                    }}
                    onClick={() =>
                      setItemQuantity(getQuantity("decrement", itemQuantity))
                    }
                  >
                    <RemoveOutlinedIcon
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: "third",
                      }}
                    />
                  </Button>
                </Box>
              </Box>

              {productConfigs?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: { lg: "flex-start" },
                    justifyContent: "space-between",
                    mb: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "14px" }}>
                    {t("color")}
                  </Typography>

                  <Box sx={{ display: "flex", gap: "12px" }}>
                    {productConfigs?.map((product) => (
                      <Box
                        key={product.id}
                        sx={{
                          border: "1px solid #F5F5F5",
                          width: "32px",
                          height: "32px",
                          borderRadius: "50%",
                          backgroundColor: product.color,
                          cursor: "pointer",
                          "&:hover": {
                            border: "1px solid",
                            borderColor: "primary.main",
                          },
                        }}
                        onClick={() => handleSelectedProductConfig(product)}
                      />
                    ))}
                  </Box>
                </Box>
              )}

              {productConfigData?.size && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    alignItems: { lg: "flex-start" },
                    mb: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "14px" }}>{t("size")}</Typography>
                  <Box sx={{ display: "flex", gap: "12px" }}>
                    <Box
                      sx={{
                        px: "10px",
                        py: "5px",
                        backgroundColor: "secondaryBackground",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", color: "#656565" }}>
                        {productConfigData?.size}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}

              <Box>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: { xs: "26px", lg: "28px" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: { xs: "#656565", lg: "secondary.main" },
                      fontWeight: { xs: "700", lg: "400" },
                    }}
                  >
                    {t("price")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      color: "secondary.main",
                      fontWeight: "700",
                    }}
                  >
                    {` ${isOnSale ? onSalePrice : regularPrice} ${t(company?.currency || "USD")} `}
                  </Typography>
                </Box> */}

                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: { xs: "#656565", lg: "secondary.main" },
                      fontWeight: { xs: "700", lg: "400" },
                    }}
                  >
                    {t("delivery")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "18px" },
                      color: { xs: "#656565", lg: "secondary.main" },
                    }}
                  >
                    {`60 ${!company?.currency ? enCurrency : arCurrency}`}
                  </Typography>
                </Box> */}

                {/* <Divider
                  sx={{
                    my: "24px",
                    backgroundColor: "#0000001A",
                    height: "1px",
                    width: "100%",
                  }}
                />

                <Box
                  sx={{
                    mb: "40px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      color: { xs: "#656565", lg: "secondary.main" },
                    }}
                  >
                    {t("total")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", lg: "20px" },
                      color: "secondary.main",
                      fontWeight: "700",
                    }}
                  >
                    {`${totalItemsPrice} ${t(company?.currency || "USD")}`}
                  </Typography>
                </Box> */}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    height: "50px",
                    borderRadius: "4px",
                    backgroundColor: "primary.main",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    textTransform: "none",
                    "&:hover": {
                      bgcolor: "primary.main",
                    },
                  }}
                  onClick={() =>
                    addProductToCart(productData, itemQuantity || 1)
                  }
                >
                  <AddShoppingCartIcon sx={{ fontSize: "16px" }} />
                  <Typography sx={{ fontSize: "16px" }}>
                    {t("add-cart")}
                  </Typography>
                </Button>

                <Button
                  sx={{
                    width: "100%",
                    height: "50px",
                    borderRadius: "4px",
                    border: "2px solid",
                    borderColor: "primary.main",
                    color: "primary.main",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                  onClick={() => {
                    addProductToCart(productData, 1);
                    handleBuyNow();
                  }}
                >
                  <Image
                    src={"/images/buyIcon.svg"}
                    alt=""
                    width={16}
                    height={16}
                  />
                  <Typography sx={{ fontSize: "16px" }}>
                    {t("buy-now")}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} mt={{ lg: "46px" }}>
        <Box
          sx={{
            borderBottom: "1px solid #F0F0F0",
            width: "100%",
            mb: { xs: "24px", lg: "56px" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "12px", lg: "16px" },
              fontWeight: { xs: "400", lg: "700" },
              borderBottom: "3px solid #820000",
              pb: { xs: "8px", lg: "25px" },
              width: "max-content",
            }}
          >
            {t("product-details")}
          </Typography>
        </Box>

        <Typography
          sx={{
            fontSize: { xs: "14px", lg: "16px" },
            width: { xs: "90%", lg: "80%" },
            lineHeight: "2",
            ml: { xs: "16px", lg: "unset" },
          }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Grid>
    </Box>
    </Container>
  );
}
