"use client";

import * as React from "react";
import { Box } from "@mui/material";

import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import NextJsImage from "./next-image";

interface BasicLightBoxProps {
  slides?: any;
  plugins?: any[];
}

export default function InlineLightBox({
  slides = [],
  plugins = [Thumbnails],
}: BasicLightBoxProps) {
  const containerRef = React.useRef(null);

  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    setIsLoaded(true);
  }, []);

  const [openFullscreen, setOpenFullscreen] = React.useState(false);

  return (
    <>
      <Box
        ref={containerRef}
        id="container"
        position={"relative"}
        sx={{
          height: "100%"
        }}
      >
        {isLoaded && (
          <Lightbox
            open={true}
            slides={slides}
            plugins={plugins}
            portal={{ root: containerRef.current }}
            on={{ click: () => setOpenFullscreen(true) }}
            render={{
              iconClose: () => null,
              slide: NextJsImage,
              thumbnail: NextJsImage,
            }}
            noScroll={{ disabled: true }}
            thumbnails={{
              width: 100,
              border: 2,
              borderRadius: 10,
              imageFit: "cover",
              borderStyle: "solid",
              borderColor: "#DDDDDD",
              padding: 8,
              gap: 16,
            }}
          />
        )}
      </Box>
      {openFullscreen && (
        <Lightbox
          slides={slides}
          open={openFullscreen}
          close={() => setOpenFullscreen(false)}
          plugins={plugins}
          thumbnails={{
            width: 100,
            border: 2,
            borderRadius: 10,
            imageFit: "cover",
            borderStyle: "solid",
            borderColor: "#DDDDDD",
            padding: 8,
            gap: 16,
          }}
        />
      )}
    </>
  );
}
